$(function () {
	$('.main-nav > .menu-item-has-children > a, .main-nav > .menu-item-has-children > span').on('click', function () {
		if ($(this).parent().hasClass('open')) {
			$(this).parent().find('.sub-menu').hide();
			$(this).parent().removeClass('open');
		} else {
			$('.main-nav li').find('.sub-menu').hide();
			$('.main-nav li').removeClass('open');

			$(this).parent().find('.sub-menu').show();
			$(this).parent().toggleClass('open');
		}

		var height = $('.open ul').height() + 48;
		$('main').css('paddingTop', height);
	});

	$(window).resize(function () {
		if ($('.open ul').height() != null && $('.open ul').height() > 0) {
			var height = $('.open ul').height() + 48;
			$('main').css('paddingTop', height);
		}
	});

	$('[name="s"]').keyup(function () {
		if ($('[name="s"]').val().length >= 1) {
			$(this).parent().find('button').addClass('text');
		} else {
			$(this).parent().find('button').removeClass('text');
		}
	});

	$('.search-icon').on('click', function () {
		$(this).toggleClass('open');
	});

	$('.toggle').on('click', function () {
		$('nav, .supporting-nav').addClass('show');
		$('.overlay').addClass('show');
		$('body').addClass('prevent');
	});

	$('nav span').on('click', function () {
		if ($('nav > ul ul').height > 0) {
			$('nav > ul ul').slideUp();
		} else {
			$(this).find('ul').slideToggle();
		}
	});

	$('.overlay').on('click', function () {
		$(this).removeClass('show');
		$('nav, .supporting-nav').removeClass('show');
		$('body').removeClass('prevent');
	});

	// var length = 0;
	// var mdCol = 3;
	// var lgCol = 4;

	// $('.main-nav ul').each(function(){
	// 	length = $(this).find('li').length;

	// 	var i = 0;
	// 	$(this).find('li').each(function(){

	// 		if( (length / mdCol) > i ) {
	// 			$(this).addClass('md-col-1');
	// 		}

	// 		if( (length / mdCol) * 2 > i && (length / mdCol) <= i  ) {
	// 			$(this).addClass('md-col-2');
	// 		}

	// 		if( (length / mdCol) * 3 > i && (length / mdCol) * 2 <= i  ) {
	// 			$(this).addClass('md-col-3');
	// 		}


	// 		if( (length / lgCol) > i ) {
	// 			$(this).addClass('lg-col-1');
	// 		}

	// 		if( (length / lgCol) * 2 > i && (length / lgCol) <= i  ) {
	// 			$(this).addClass('lg-col-2');
	// 		}

	// 		if( (length / lgCol) * 3 > i && (length / lgCol) * 2 <= i  ) {
	// 			$(this).addClass('lg-col-3');
	// 		}

	// 		if( (length / lgCol) * 4 > i && (length / lgCol) * 3 <= i  ) {
	// 			$(this).addClass('lg-col-4');
	// 		}

	// 		i = i + 1;
	// 	});

	// 	var mdCol1Height = $(this).find('.md-col-1').length;
	// 	$(this).addClass('md-margin-offset-' + mdCol1Height);

	// 	var lgCol1Height = $(this).find('.lg-col-1').length;
	// 	$(this).addClass('lg-margin-offset-' + lgCol1Height);

	// 	$(this).find('.md-col-2').first().addClass('first');
	// 	$(this).find('.md-col-3').first().addClass('first');

	// });

});

// Get scrollbar width to be used in calculations
document.documentElement.style.setProperty('--scrollbar-width', window.innerWidth - document.documentElement.clientWidth + "px");

const ctaSidebar = document.querySelector('.sidebar');

if (ctaSidebar) {

	// The .row
	const grandParent = ctaSidebar.parentElement.parentElement;

	const globalSiteHeader = document.getElementById('site-header');

	const hero = document.querySelector('.hero');
	let heroHeight = 0;

	const pinnedClass = 'pinned';
	const topBuffer = 100;
	const bottomBuffer = 100;

	// Changes the sidebar to fixed/static on scroll
	window.addEventListener('scroll', event => {

		if (window.innerWidth >= 721) {
			// Account for the height of the hero section if it exists
			if (hero) {
				heroHeight = hero.getBoundingClientRect().height;
				const heroStyles = getComputedStyle(hero);

				// Offset the bottom margin of hero section
				heroHeight += parseInt(heroStyles.marginBottom);
			}

			const sidebarHeight = ctaSidebar.getBoundingClientRect().height;
			const grandparentHeight = grandParent.getBoundingClientRect().height;

			// Reached ~bottom of page, clear the top value and set a bottom value instead
			// The bottom buffer is for offsetting the "BBB" that has position absolute and negative positioning below sidebar content
			if (window.scrollY + sidebarHeight + bottomBuffer > grandparentHeight) {
				ctaSidebar.style.top = '';
				ctaSidebar.style.bottom = bottomBuffer + 'px';
				ctaSidebar.classList.add(pinnedClass);
			}
			// Past the hero and site header, so the sidebar starts to scroll
			else if (window.scrollY > heroHeight + globalSiteHeader.offsetHeight - topBuffer) {
					ctaSidebar.style.top = window.scrollY - heroHeight + 'px';
					ctaSidebar.style.bottom = '';
					ctaSidebar.classList.add(pinnedClass);
				}
				// Back at the top of the page so reset top/bottom values
				else {
						ctaSidebar.style.top = '';
						ctaSidebar.style.bottom = '';
						ctaSidebar.classList.remove(pinnedClass);
					}
		} else {
			ctaSidebar.style.top = '';
			ctaSidebar.style.bottom = '';
			ctaSidebar.classList.remove(pinnedClass);
		}
	});
}